<script setup lang="ts">
const route = useRoute();
const isComparePage = computed(() => {
  return route.path.startsWith("/compare/");
});
</script>

<template>
  <div>
    <OrganismsHeader />
    <main :class="{ compare: isComparePage }">
      <slot />
      <AtomsScrollToPageTopBtn />
    <OrganismsNote />
    <OrganismsCompareTerms v-if="isComparePage" :campaign-lp-display="false" />
    <OrganismsTerms v-else :campaign-lp-display="false" />
    </main>
    <footer>
      <div class="footer-link">
        <ul>
          <li>
            <a href="javascript:void(0);" @click="$popUp.privacyWin()"
              >プライバシーポリシー</a
            >｜
          </li>
          <li>
            <a href="javascript:void(0);" @click="$popUp.termWin()">利用規約</a
            >｜
          </li>
          <li>
            <a href="https://www.webcrew.co.jp/" target="_blank" rel="noopener"
              >運営会社</a
            >｜
          </li>
          <li>
            <a href="/sitemap/">サイトマップ</a>
          </li>
        </ul>
      </div>

      <div class="footer-bangOtherLink">
        <img
          src="~/assets/images/logo_bang.gif"
          alt="bang!"
          width="76"
          height="48"
          class="logo"
        />
        <img
          src="~/assets/images/tagline_bang.gif"
          alt="保険の総合情報サイト【保険スクエアbang!】～保険に関する最新ニュースから、保険選びのサポートまで～"
          width="829"
          height="18"
          class="bangCatch"
        />
        <ul>
          <li>
            <a href="https://www.bang.co.jp/" target="_blank" rel="noopener"
              >自動車保険</a
            >｜
          </li>
          <li>
            <a
              href="https://www.bang.co.jp/hojin/"
              target="_blank"
              rel="noopener"
              >自動車保険（法人）</a
            >｜
          </li>
          <li>
            <a
              href="https://www.bang.co.jp/bike/"
              target="_blank"
              rel="noopener"
              >バイク保険</a
            >｜
          </li>
          <li>
            <a
              href="https://www.bang.co.jp/bicycle/"
              target="_blank"
              rel="noopener"
              >自転車保険</a
            >｜
          </li>
          <li>
            <a href="https://life.bang.co.jp/" target="_blank" rel="noopener"
              >生命保険</a
            >｜
          </li>
          <li><a href="/">火災保険</a>｜</li>
          <li>
            <a
              href="https://www.bang.co.jp/travel/"
              target="_blank"
              rel="noopener"
              >旅行保険</a
            >｜
          </li>
          <li>
            <a
              href="https://www.bang.co.jp/golf/"
              target="_blank"
              rel="noopener"
              >ゴルフ保険</a
            >
          </li>
        </ul>
      </div>

      <div class="footer-about">
        <div>
          <div class="footer-about-iso">
            <img
              src="https://p1-a392e26b.imageflux.jp/w=91/images/logo_ISMS.png"
              alt=""
              width="91"
              height="40"
            />
            <p>
              <span>ISOを取得しています</span><br />
              当社はお客様から取得する個人情報を含めすべての情報についてISO27001に基づく管理を行い、情報のセキュリティを確保しています。
            </p>
          </div>
          <div class="footer-about-zba">
            <img
              src="~/assets/images/logo_zba.gif"
              alt=""
              width="82"
              height="45"
            />
            <p>
              「ズバット」は、株式会社ウェブクルーが展開する、サービスブランドです。
            </p>
          </div>
        </div>
      </div>

      <div class="footer-iso">
        <div>
          <span>ISOを取得しています</span>
          当社はお客様から取得する個人情報を含めすべての情報についてISO27001に基づく管理を行い、<br />情報のセキュリティを確保しています。
        </div>
      </div>

      <small class="copyright"
        ><img
          src="~/assets/images/logo_wc.gif"
          alt="webcrew"
          width="164"
          height="16"
          class="logo"
        />Copyright &copy; 2000 WebCrew Inc. All rights reserved.</small
      >
    </footer>
  </div>
</template>

<style lang="scss" scoped>
/*---------------------------------------
footer
----------------------------------------*/
.footer-link {
  padding: 0 4%;
  background-color: #f8f7f3;

  ul {
    padding: 1em 0;
    border-top: 1px solid #dcdcdc;
    text-align: center;
    overflow: hidden;
    line-height: 0.8em;
    letter-spacing: -0.4em;
  }

  li {
    display: inline-block;
    letter-spacing: 0;

    a {
      padding: 0;
      color: #148cde;
      font-size: px(11.2);
      vertical-align: top;
    }

    &:first-child a {
      padding-left: 0;
    }

    &:last-child a {
      padding-right: 0;
    }
  }
}

.footer-about,
.footer-bangOtherLink {
  display: none;
}

.footer-iso > div {
  padding: 4% 4% 4% 37%;
  font-size: px(12.2);
  background: url(https://p1-a392e26b.imageflux.jp/images/logo_ISMS.png) 4%
    center no-repeat;
  background-size: 30% auto;

  > span {
    display: block;
    color: #333;
    font-size: px(16.8);
    font-weight: bold;
  }
}

.copyright {
  padding: 2.5% 4% 3.5%;
  border-top: 1px solid #dcdcdc;
  display: block;
  font-size: px(12.5);
  text-align: center;
  line-height: 1.2;

  img {
    margin: 0 auto 1%;
    width: 50%;
    height: auto;
  }
}

@include media(pc) {
  /*---------------------------------------
  footer
  ----------------------------------------*/

  .footer-link {
    padding: 0;

    ul {
      margin: 0 auto;
      padding: 1.5em 0;
      width: $pcWrapWidth;
    }

    li a {
      padding: 0 0.4em;
      font-size: px(12.9);
    }
  }

  .footer-bangOtherLink {
    margin: 0 auto;
    padding: 15px 0 12px;
    width: $pcWrapWidth;
    display: block;
    box-sizing: border-box;

    .logo {
      margin: 0 10px 0 0;
      width: 76px;
      height: 48px;
      display: inline-block;
      vertical-align: middle;
    }

    .bangCatch {
      width: 829px;
      height: 18px;
      display: inline-block;
      vertical-align: middle;
    }

    ul {
      margin: 0 auto;
      padding: 14px 0 0;
      overflow: hidden;
      line-height: 0.8em;
      letter-spacing: -0.4em;
    }

    li {
      display: inline-block;
      letter-spacing: 0;

      a {
        color: #333;
        font-size: px(12.9);
      }
    }
  }

  .footer-about {
    margin: 0 auto;
    padding: 3px;
    border-radius: 3px;
    width: $pcWrapWidth;
    display: block;
    background-color: #f8f7f3;

    > div {
      padding: 9px 8px 6px;
      border: 1px solid #e5e4e0;
      border-radius: 5px;
      overflow: hidden;
      background-color: #fff;
    }
  }

  .footer-about-iso {
    width: 470px;
    float: left;

    img {
      padding: 3px 0;
      width: 91px;
      height: auto;
    }

    p {
      margin: -47px 0 0 95px;
      font-size: px(10.9);

      span {
        font-weight: bold;
      }
    }
  }

  .footer-about-zba {
    width: 467px;
    float: right;

    img {
      width: 82px;
      height: 45px;
    }

    p {
      margin: -28px 0 0 83px;
      font-size: px(10.9);
    }
  }

  .footer-iso {
    display: none;
  }

  .copyright {
    margin: 0 auto;
    padding: 10px 0 28px;
    border-top: none;
    width: $pcWrapWidth;
    display: block;
    font-size: px(12.5);

    img {
      margin: 0 8px 0 0;
      width: 164px;
      height: 16px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
</style>
