<template>
  <div class="note counter">
    <ul>
      <li>
        ※&nbsp;「サイト利用者数800万人」は、保険スクエアbang!
        累計利用者数（2024年6月現在）の数字です。
      </li>
    </ul>
    <!-- .note -->
  </div>
</template>

<style lang="scss" scoped>
.note {
  padding: 4%;
  background-color: #f8f7f3;

  li {
    margin: 0 0 2% 1.5em;
    color: #666;
    font-size: px(12);
    text-indent: -1.5em;
  }
}

@include media(pc) {
  .note {
    padding: 0;

    ul {
      margin: 0 auto;
      padding: 30px 0 12px;
      width: $pcWrapWidth;
      box-sizing: border-box;
    }

    li {
      margin: 0 0 3px 1.5em;
      color: #666;
      font-size: px(12.9);
      text-indent: -1.5em;
    }
  }
}
</style>
