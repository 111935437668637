<template>
  <header id="header" role="banner">
    <div class="header-logoAreaWrap">
      <div class="header-logoArea">
        <div class="header-bangOtherLink">
          <ul>
            <li>
              <a href="https://www.bang.co.jp/" target="_blank" rel="noopener"
                >自動車保険</a
              >｜
            </li>
            <li>
              <a
                href="https://www.bang.co.jp/hojin/"
                target="_blank"
                rel="noopener"
                >自動車保険（法人）</a
              >｜
            </li>
            <li>
              <a
                href="https://www.bang.co.jp/bike/"
                target="_blank"
                rel="noopener"
                >バイク保険</a
              >｜
            </li>
            <li>
              <a
                href="https://www.bang.co.jp/bicycle/"
                target="_blank"
                rel="noopener"
                >自転車保険</a
              >｜
            </li>
            <li>
              <a href="https://life.bang.co.jp/" target="_blank" rel="noopener"
                >生命保険</a
              >｜
            </li>
            <li><a href="/">火災保険</a>｜</li>
            <li>
              <a
                href="https://www.bang.co.jp/travel/"
                target="_blank"
                rel="noopener"
                >旅行保険</a
              >｜
            </li>
            <li>
              <a
                href="https://www.bang.co.jp/golf/"
                target="_blank"
                rel="noopener"
                >ゴルフ保険</a
              >
            </li>
          </ul>
        </div>
        <div class="header-logo">
          <a href="/"
            ><img
              src="~/assets/images/logo.png"
              width="299"
              height="99"
              alt="保険スクエアbang! 火災保険"
          /></a>
        </div>
        <div class="header-appeal">
          サイト利用者数※
          <span><span>800万人</span>突破!</span>
        </div>
      </div>
    </div>
    <MoleculeGlobalNavPc />
    <MoleculeGlobalNavSp />
  </header>
</template>

<style lang="scss" scoped>
/*---------------------------------------
header
----------------------------------------*/
#header {
  min-height: 14vw;
  display: flex;
  align-items: center;

  .header-logoArea {
    width: 75%;
    padding: 2.1% 2.9% 2.1% 4.2%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
  }

  .header-bangOtherLink {
    display: none;
  }

  .header-logo {
    width: 40%;
  }
}

.header-appeal {
  font-size: px(10.9);
  text-align: right;
}

#header .header-appeal > span {
  display: block;
  font-size: px(26.7);
  font-weight: bold;
  line-height: 1;

  span {
    color: #f15b26;
    font-weight: bold;
  }
}


@include media(pc) {
  /*---------------------------------------
  header
  ----------------------------------------*/

  #header {
    display: block;
    min-height: auto;

    .header-logoArea {
      margin: 0 auto;
      padding: 5px 0 14px;
      width: $pcWrapWidth;
    }

    .header-bangOtherLink {
      margin: 0 0 8px;
      width: 100%;
      display: block;

      ul {
        overflow: hidden;
        line-height: 1;
        letter-spacing: -0.4em;
      }

      li {
        display: inline-block;
        color: #ccc;
        letter-spacing: 0;
      }
    }
  }

  .header-bangOtherLink li a {
    color: #333;
    font-size: px(12);
    vertical-align: top;
    text-decoration: none;
  }

  #header {
    .header-logo {
      width: 200px;
    }

    .header-appeal {
      width: 210px;
      font-size: px(12.9);

      > span {
        font-size: px(34.8);
      }
    }
  }
}
</style>
