<template>
  <section class="terms">
    <div>
      <h2>【「保険スクエアbang! 火災保険」ご利用に関するご注意】</h2>
      <ul>
        <li>
          株式会社ウェブクルーが運営する「保険スクエアbang!
          火災保険」（以下、当サイト）では、火災保険の診断を依頼することができます。
        </li>
        <li>
          株式会社ウェブクルーは火災保険の媒介・募集・販売行為は行いません。
        </li>
        <li>
          「賃貸物件の方はこちら（外部サイト）」遷移後は、チューリッヒ少額短期保険のページです。
        </li>
        <li>
          保険取扱代理店が火災保険で推奨する商品のある損害保険会社をご案内しております。
        </li>
        <li>
          詳細は各保険会社のホームページに記載されている約款・重要事項説明書をご確認ください。
        </li>
        <li>
          架空の個人情報（氏名、電話番号、メールアドレス）を入力することは、詐欺行為に該当する悪質な犯罪行為です。
        </li>
      </ul>

      <h3>【診断できない条件について】</h3>
      <ul>
        <li>
          住宅金融支援機構の融資を受けていて、住宅金融支援機構特約火災保険に加入中の建物
        </li>
        <li>住居と併用していない店舗・事務所等の建物および家財</li>
        <li>賃貸借契約の建物</li>
      </ul>

      <h3>【引受保険会社】</h3>
      <ul class="tableList">
        <li>楽天損害保険株式会社</li>
        <li>ソニー損害保険株式会社</li>
        <li>セゾン自動車火災保険株式会社</li>
      </ul>
    </div>
  </section>
</template>

<style lang="scss" scoped>
/*---------------------------------------
.terms
----------------------------------------*/
.terms {
  background-color: #f8f7f3;

  > div {
    padding: 4%;

    h2,
    h3 {
      margin: 0 0 0.5em;
      font-size: px(12);
      font-weight: 700;
    }

    ul:not(:last-of-type) {
      margin: 0 0 1.5em;
    }

    li {
      padding: 0 0 0 0.7em;
      font-size: px(12);
      background: url(~/assets/images/list_dot.png) no-repeat left 0.7em;
      background-size: 4px auto;
      line-height: 1.74;

      a {
        color: #148cde;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

#promo {
  .terms {
    border-bottom: 1px solid #aec4d0;
    background-color: #e9f0f5;

    > div {
      padding: 4%;
      font-size: px(12.5);

      h2,
      h3 {
        margin-bottom: 3%;
        color: #666;
        font-size: px(17);
        font-weight: bold;
      }

      ul {
        margin-bottom: 8%;
      }

      li {
        margin-bottom: 1.5%;
        padding-left: 3%;
        color: #666;
        font-size: px(17);
        background: url(~/assets/images/promo/landing/list_dot.png) no-repeat
          left 0.6em;
        background-size: 4px auto;
        line-height: 1.5;

        a {
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      h3 + span {
        margin-bottom: 4%;
        display: block;
        color: #666;
        font-size: px(17);
      }
    }

    .button {
      padding: 4% 0;
      font-size: px(15);
    }
  }

  &.pageIndex02 .terms > div {
    h2,
    h3 {
      margin: 0 0 2%;
      font-size: px(14.6);
    }

    padding: 6% 4% 7%;

    ul {
      margin-bottom: 4%;
    }

    li {
      margin: 0;
      padding-left: 3%;
      font-size: px(14.4);
      line-height: 1.7;
    }
  }

  &.outsource .terms {
    margin: 0 auto 8vw;
    width: 94%;
    border-bottom: none;
    background-color: #eaf7ff;

    > div {
      padding: 0;
      font-size: px(15);

      h2,
      h3 {
        margin: 0;
        color: #2e2e2e;
        font-size: 2.9vw;
        font-weight: 700;
        margin-bottom: 2vw;
      }

      ul {
        margin-bottom: 4vw;
      }

      li {
        margin: 0;
        padding-left: 1em;
        color: #2e2e2e;
        font-size: 2.7vw;
        background: url(~/assets/images/promo/landing/list_dot.png) no-repeat
          left 0.7em;
        background-size: 4px auto;
        line-height: 1.8;

        a {
          color: #2e2e2e;
          text-decoration: none;
        }
      }
    }
  }
}

@include media(pc) {
  /*---------------------------------------
  .terms
  ----------------------------------------*/

  .terms {
    padding: 48px 0 0;

    > div {
      margin: 0 auto;
      padding: 0 0 25px;
      width: $pcWrapWidth;

      h2,
      h3,
      ul {
        margin: 0 auto;
        width: $pcWrapWidth;
      }

      li {
        font-size: px(12.9);
        line-height: 1.92;
      }
    }
  }

  #promo {
    .terms {
      padding: 0;
      border-bottom: none;

      > div {
        padding: 48px 0 0;
        width: 920px;
        overflow: hidden;

        h2,
        h3 {
          margin-bottom: 1%;
          font-size: px(15);
        }

        ul {
          margin-bottom: 3.5%;
          width: 920px;
        }

        li {
          margin-bottom: 0.5%;
          padding-left: 1%;
          font-size: px(15);
        }
      }

      .button {
        padding: 5% 0;
      }
    }

    &.pageIndex02 .terms {
      padding: 33px 0 0;

      > div {
        padding: 0;
        width: 860px;

        h2,
        h3 {
          margin: 0 0 5px;
          padding: 0;
        }

        ul {
          margin: 0 0 50px;
        }

        li {
          padding: 0 0 0 10px;
        }
      }

      .button {
        padding: 5% 0;
        width: auto;
      }

      > div .tableList {
        column-count: 3;
      }
    }

    &.outsource .terms {
      margin: 0 auto 40px;
      width: 650px;

      > div {
        width: auto;

        h2,
        h3 {
          width: auto;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 10px;
        }

        ul {
          margin-bottom: 30px;
          width: auto;
        }

        li {
          padding-left: 1em;
          font-size: 12px;
          background: url(~/assets/images/promo/landing/list_dot.png) no-repeat
            left 0.7em;
          background-size: 4px auto;
        }
      }
    }
  }
}
</style>
