<template>
  <div class="globalNavWrap">
    <!-- SP ハンバーガーメニュー用ボタン -->
    <button
      class="menu-trigger"
      :class="{ 'is-active': displayGlobalNav }"
      @click="GlobalNavToggle"
    >
      <span></span>
      <span></span>
      <span></span>
    </button>
    <!-- グロナビ SP -->
    <div class="globalNav-spWrap">
      <!-- グロナビの背景 -->
      <div
        v-if="displayGlobalNav"
        class="globalNav-sp-backdrop"
        @click="GlobalNavToggle"
      ></div>
      <!-- トランジション -->
      <transition name="slide-side">
        <!-- サイドナビ -->
        <div v-if="displayGlobalNav" class="globalNav-sp">
          <ul class="globalNav-list">
            <li
              v-for="(item, index) in getContentsSp"
              :key="index"
              class="globalNav-item"
            >
              <div v-if="item.content.length > 0">
                <button
                  type="button"
                  :class="{
                    'is-open': isOpen[item.id],
                  }"
                  class="globalNav-sp-button accordion-switch"
                  @click="handleToggle(item.id)"
                >
                  <span v-text="item.name" />
                </button>
                <transition
                  name="accordion"
                  @before-enter="beforeEnter"
                  @enter="enter"
                  @before-leave="beforeLeave"
                  @leave="leave"
                >
                  <ul v-show="isOpen[item.id]" class="accordion">
                    <li
                      v-for="(contentItem, index) in item.content"
                      :key="index"
                    >
                      <a
                        :id="contentItem.gaId"
                        :href="contentItem.url"
                        :target="contentItem.blank ? '_blank' : ''"
                        v-text="contentItem.name"
                      />
                    </li>
                  </ul>
                </transition>
              </div>
              <div v-else>
                <a :id="item.gaId" :href="item.url" class="globalNav-sp-button">
                  <span>{{ item.name }}</span>
                </a>
              </div>
            </li>
          </ul>
          <div class="globalNav-request">
            <img
              src="~/assets/images/button_catch_nav.png"
              width="496"
              height="44"
              alt="カンタン3分!最短即日見積もり!"
              class="globalNav-request-catch"
            />
            <a href="/step/step_init.html" class="button-step1"
              ><span>無料</span>
              <div>お見積もりはこちら!</div></a
            >
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getContentsSp } from "~/assets/ts/globalNavContentsList";

const displayGlobalNav = ref(false);

const isOpen = ref<boolean[]>([]);

const handleToggle = (index: any) => {
  if (!isOpen.value[index]) {
    isOpen.value = Array(getContentsSp.length).fill(false);
  }
  isOpen.value.splice(index, 1, !isOpen.value[index]);
};

isOpen.value = Array(getContentsSp.length).fill(false);

const GlobalNavToggle = () => {
  displayGlobalNav.value = !displayGlobalNav.value;
};

const beforeEnter = (el: any) => {
  el.style.height = "0";
};
const enter = (el: any) => {
  el.style.height = el.scrollHeight + "px";
};
const beforeLeave = (el: any) => {
  el.style.height = el.scrollHeight + "px";
};
const leave = (el: any) => {
  el.style.height = "0";
};
</script>

<style lang="scss" scoped>
.globalNavWrap {
  width: 14vw;
  height: 14vw;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  margin-left: auto;
  z-index: 2;
}

.compare .globalNavWrap {
  position: absolute;
}

.menu-trigger {
  display: inline-block;
  transition: all 0.4s;
  box-sizing: border-box;

  span {
    display: inline-block;
    transition: all 0.4s;
    box-sizing: border-box;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    width: 50%;
    height: 4px;
    background-color: #fff;
    border-radius: 2px;

    &:nth-of-type(1) {
      top: 20.6%;
    }

    &:nth-of-type(2) {
      top: 35%;
    }

    &:nth-of-type(3) {
      top: 50%;
    }
  }

  position: relative;
  width: 100%;
  background: none;
  border: none;
  appearance: none;
  cursor: pointer;
  background-color: $colorBasicBackground;
  z-index: 99999;

  &::after {
    position: absolute;
    left: 0;
    bottom: 11%;
    content: 'MENU';
    display: block;
    width: 100%;
    color: #fff;
    font-size: px(10);
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    transition: all 0.4s;
  }

  &.is-active {
    &::after {
      content: 'CLOSE';
    }

    span {
      &:nth-of-type(1) {
        transform: translateY(20px) rotate(-45deg);
        top: 1px;
      }

      &:nth-of-type(2) {
        opacity: 0;
      }

      &:nth-of-type(3) {
        transform: translateY(-20px) rotate(45deg);
        top: 41px;
      }
    }
  }
}

.is-floating {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.globalNav-list {
  margin-bottom: 8.5%;
  list-style: none;
  padding: 0;
}

.globalNav-item a {
  text-decoration: none;
  color: $colorWhite;
  font-weight: bold;
  display: block;
}
.globalNav-spWrap {
  display: block;
}

.globalNav-sp-button {
  margin: 0;
  display: block;
  width: 100%;
  text-align: left;
  color: $colorWhite;
  font-weight: bold;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  background: url(~/assets/images/arrow_right_nav_sp.png) no-repeat right 6%
    center;
  background-size: 2.8% auto;

  &.accordion-switch {
    position: static;
    background: url(~/assets/images/icon_plus.png) no-repeat right 5.33% center;
    background-size: 5% auto;
  }
}

.accordion-switch.is-open {
  background: url(~/assets/images/icon_minus.png) no-repeat right 5.33% center;
  background-size: 5% auto;
}

.globalNav-sp-button {
  &.accordion-switch:before {
    content: none;
  }

  span {
    display: block;
    padding: 4.3%;
    font-weight: bold;
  }
}

/* グロナビの背景 */

.globalNav-sp-backdrop {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
}

.globalNav-sp {
  height: 100%;
  width: 74.7%;
  background-color: $colorBasicBackground;
  z-index: 10000;
  position: fixed;
  top: 0;
  right: 0;
  box-sizing: border-box;
  padding: 18% 0 26%;
}

.slide-side-enter-active,
.slide-side-leave-active {
  transition: opacity 0.3s;
}

.slide-side-enter,
.slide-side-leave-to {
  opacity: 0;
}

/* アコーディオン */

.accordion {
  transition: height 0.3s ease-in-out;
  overflow: hidden;
  background: #0c5d8e;
  padding: 0 5.33%;

  li {
    position: relative;

    a {
      padding: 4%;
      border-bottom: 1px solid rgba(255, 255, 255, 0.24);
    }

    &:first-child a {
      padding-top: 8.53%;
    }

    &:last-child a {
      border-bottom: none;
      padding-bottom: 8.53%;
    }

    &:before {
      margin: auto;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 4.8%;
      border-bottom: 2px solid rgba(255, 255, 255, 0.5);
      border-right: 2px solid rgba(255, 255, 255, 0.5);
      border-radius: 2px;
      width: 2.5vw;
      height: 2.5vw;
      transform: rotate(315deg);
      pointer-events: none;
    }

    &:first-child:before {
      top: 18%;
    }

    &:last-child:before {
      bottom: 23%;
    }
  }
}

.accordion-enter-active,
.accordion-leave-active {
  animation-duration: 0.3s;
  animation-fill-mode: both;
}

/* CVボタン */

.globalNav-request {
  display: block;
  padding: 0 4.5% 0;
}

.globalNav-request-catch {
  margin-bottom: 2.1%;
}

.button-step1 {
  margin: 0 auto 14%;
  padding: 4.4% 0;
  border-radius: 6px;
  display: block;
  color: #fff;
  font-weight: bold;
  background-color: $colorBasicButton;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.16);
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  transition: 0.1s all;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.3);

  > {
    span {
      margin: 0 0.2em 0 0;
      padding: 0.2em 0.2em 0.3em;
      display: inline-block;
      color: #f00;
      font-size: px(14);
      font-weight: 700;
      background-color: #ff0;
      line-height: 1;
      vertical-align: middle;
    }

    div {
      padding: 0 11% 0 0;
      display: inline-block;
      color: #fff;
      font-size: px(16);
      font-weight: 700;
      background: url(~/assets/images/arrow_orange_sp.png) no-repeat right
        center;
      background-size: 12% auto;
      vertical-align: middle;
      text-shadow: 2px 2px 0px rgba(241, 90, 36, 0.4);
    }
  }
}

.globalNav-pc {
  display: none;
}

@include media(pc) {
  .menu-trigger {
    display: none;
  }
  .globalNav-spWrap {
    display: none;
  }
  .globalNav-request {
    display: none;
  }
}
</style>
