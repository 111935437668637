<template>
  <div class="pageTop">
    <a href="#header" v-smooth-scroll
      ><img
        src="~/assets/images/page_top.png"
        width="180"
        height="122"
        alt="TOP"
    /></a>
  </div>
</template>

<style lang="scss" scoped>
.pageTop {
  padding: 0 4%;
  a {
    margin: 0 0 0 auto;
    width: 26%;
    display: block;
  }
}

@include media(pc) {
  .pageTop {
    margin: 0 auto;
    width: 960px;
    a {
      width: 132px;
      height: 88px;
      display: block;
      cursor: pointer;
    }
    &:hover {
      opacity: .8;
    }
  }
}
</style>
