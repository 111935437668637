<template>
  <!-- TODO compare配下はis-floating付与しないように -->
  <div
    v-scroll="handleScroll"
    class="globalNav-pc"
    :class="{
      'is-floating': isFloating,
    }"
  >
    <ul class="globalNav-list">
      <li v-for="(item, index) in getContentsPc" class="globalNav-item">
        <div v-if="item.content.length > 0">
          <div
            @mouseover="mouseOverAction(index)"
            @mouseleave="mouseLeaveAction(index)"
            class="globalNav-button display-menu"
          >
            <a :id="item.gaId" :href="item.pcOnlyNavUrl">{{ item.name }}</a>
            <transition name="dropDown-menu">
              <div v-if="isHoverFlg && index === hoverIndex">
                <ul class="dropDown-menu">
                  <li v-for="contentItem in item.content" :key="item.id">
                    <a
                      :id="contentItem.gaId"
                      :href="contentItem.url"
                      :target="contentItem.blank ? '_blank' : ''"
                      class="link-button"
                      :class="{
                        activeClass: $route.path === contentItem.url,
                      }"
                      v-text="contentItem.name"
                    />
                  </li>
                </ul>
              </div>
            </transition>
          </div>
        </div>
        <div v-else>
          <a
            :id="item.gaId"
            :href="item.url"
            class="globalNav-button buttonOnly"
            v-text="item.name"
          />
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { getContentsPc } from "~/assets/ts/globalNavContentsList";

const isFloating = ref(false);

const hoverIndex = ref(null);

const isHoverFlg = ref(false);

const handleScroll = () => {
  if (window.scrollY > 50) {
    isFloating.value = true;
  } else {
    isFloating.value = false;
  }
};
const mouseOverAction = (index: any) => {
  isHoverFlg.value = true;
  hoverIndex.value = index;
};
const mouseLeaveAction = (index: any) => {
  isHoverFlg.value = false;
  hoverIndex.value = index;
};
</script>

<style lang="scss" scoped>
.globalNav-pc {
  display: none;
}
@include media(pc) {
  .globalNav-pc {
    margin: 0 auto;
    height: 48px;
    display: block;
    background: $colorBlue;
  }

  .compare .globalNav-pc {
    position: relative;
  }

  .globalNav-spWrap {
    display: none;
  }

  .globalNav-list {
    margin: 0 auto;
    list-style: none;
    display: flex;
    width: $pcWrapWidth;
    padding: 0;
  }
  .globalNav-item {
    width: 100%;
      a {
      text-decoration: none;
      color: $colorWhite;
      font-weight: bold;
      display: block;
    }
    .globalNav-button:hover:before,
    &:hover + .globalNav-item .globalNav-button:before {
      content: none;
    }
  }
  .globalNav-button {
    position: relative;
    color: $colorWhite;
    font-weight: bold;
    text-align: center;
    padding: 13px 0;
    cursor: pointer;

    &:hover > a {
      color: $colorBlue;
    }

    &.buttonOnly:hover {
      background: #ebf9ff;
      color: $colorBlue;
    }
    &.display-menu {
      background: url(~/assets/images/arrow_nav_button.png) no-repeat right
        25px center;

      &:hover {
        background: url(~/assets/images/arrow_nav_button_hover.png) no-repeat
          right 25px center #ebf9ff;
      }
    }

    &.is-active {
      background: #ebf9ff;
      color: $colorBlue;
    }

    &:before {
      margin: auto;
      content: '';
      height: 32px;
      border-left: 1px solid #ffffff;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
    }
  }

  .globalNav-item:last-child .globalNav-button:after {
    margin: auto;
    content: '';
    height: 32px;
    border-right: 1px solid #ffffff;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .globalNav-request {
    display: none;
  }

  .dropDown-menu-enter-active,
  .dropDown-menu-leave-active {
    transition: opacity 0.3s;
  }

  .dropDown-menu-enter,
  .dropDown-menu-leave-to {
    opacity: 0;
  }

  .dropDown-menu {
    margin: auto;
    width: $pcWrapWidth;
    background: #ebf9ff;
    padding: 16px 42px 24px;
    position: fixed;
    top: 156px;
    left: 0;
    right: 0;
    box-sizing: border-box;
    display: flex;
    z-index: 2;
    li {
      margin-right: 24px;
      width: 200px;
      border-bottom: 1px solid $colorBlue;

      &:nth-child(4n) {
        margin-right: 0;
      }

      .link-button {
        background: url(~/assets/images/arrow_right_nav.png) no-repeat right
          10px center;
        color: $colorBasicText;
        text-align: left;
        padding: 7px 10px 10px 8px;
        transition: opacity 0.3s;

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  .is-floating {
    margin: auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;

    .dropDown-menu {
      top: 49px;
    }
  }
}
</style>
